:root {
  --plt-primary-000: hsl(202, 100%, 95%);
  --plt-primary-100: hsl(204, 100%, 86%);
  --plt-primary-200: hsl(206, 93%, 73%);
  --plt-primary-300: hsl(208, 88%, 62%);
  --plt-primary-400: hsl(210, 83%, 53%);
  --plt-primary-500: hsl(212, 92%, 43%);
  --plt-primary-600: hsl(214, 95%, 36%);
  --plt-primary-700: hsl(215, 96%, 32%);
  --plt-primary-800: hsl(216, 98%, 25%);
  --plt-primary-900: hsl(218, 100%, 17%);

  --plt-neutral-000: hsl(216, 33%, 97%);
  --plt-neutral-100: hsl(214, 15%, 91%);
  --plt-neutral-200: hsl(210, 16%, 82%);
  --plt-neutral-300: hsl(211, 13%, 65%);
  --plt-neutral-400: hsl(211, 10%, 53%);
  --plt-neutral-500: hsl(211, 12%, 43%);
  --plt-neutral-600: hsl(209, 14%, 37%);
  --plt-neutral-700: hsl(209, 18%, 30%);
  --plt-neutral-800: hsl(209, 20%, 25%);
  --plt-neutral-900: hsl(210, 24%, 16%);

  --plt-supporting-0-000: hsl(171, 82%, 94%);
  --plt-supporting-0-100: hsl(172, 97%, 88%);
  --plt-supporting-0-200: hsl(174, 96%, 78%);
  --plt-supporting-0-300: hsl(176, 87%, 67%);
  --plt-supporting-0-400: hsl(178, 78%, 57%);
  --plt-supporting-0-500: hsl(180, 77%, 47%);
  --plt-supporting-0-600: hsl(182, 85%, 39%);
  --plt-supporting-0-700: hsl(184, 90%, 34%);
  --plt-supporting-0-800: hsl(186, 91%, 29%);
  --plt-supporting-0-900: hsl(188, 91%, 23%);

  --plt-supporting-1-000: hsl(24, 100%, 93%);
  --plt-supporting-1-100: hsl(22, 100%, 85%);
  --plt-supporting-1-200: hsl(20, 100%, 77%);
  --plt-supporting-1-300: hsl(18, 100%, 70%);
  --plt-supporting-1-400: hsl(16, 94%, 61%);
  --plt-supporting-1-500: hsl(14, 89%, 55%);
  --plt-supporting-1-600: hsl(12, 86%, 47%);
  --plt-supporting-1-700: hsl(10, 93%, 40%);
  --plt-supporting-1-800: hsl(8, 92%, 35%);
  --plt-supporting-1-900: hsl(6, 96%, 26%);

  --plt-supporting-2-000: hsl(0, 100%, 95%);
  --plt-supporting-2-100: hsl(0, 100%, 87%);
  --plt-supporting-2-200: hsl(0, 100%, 80%);
  --plt-supporting-2-300: hsl(0, 91%, 69%);
  --plt-supporting-2-400: hsl(0, 83%, 62%);
  --plt-supporting-2-500: hsl(356, 75%, 53%);
  --plt-supporting-2-600: hsl(354, 85%, 44%);
  --plt-supporting-2-700: hsl(352, 90%, 35%);
  --plt-supporting-2-800: hsl(350, 94%, 28%);
  --plt-supporting-2-900: hsl(348, 94%, 20%);

  --plt-supporting-3-000: hsl(49, 100%, 96%);
  --plt-supporting-3-100: hsl(48, 100%, 88%);
  --plt-supporting-3-200: hsl(48, 95%, 76%);
  --plt-supporting-3-300: hsl(48, 94%, 68%);
  --plt-supporting-3-400: hsl(44, 92%, 63%);
  --plt-supporting-3-500: hsl(42, 87%, 55%);
  --plt-supporting-3-600: hsl(36, 77%, 49%);
  --plt-supporting-3-700: hsl(29, 80%, 44%);
  --plt-supporting-3-800: hsl(22, 82%, 39%);
  --plt-supporting-3-900: hsl(15, 86%, 30%);
}
