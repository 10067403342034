.toast {
  background-color: var(--plt-neutral-900);
  color: white;
  padding: var(--sp-2) var(--sp-3);
  position: fixed;
  bottom: var(--sp-3);
  right: var(--sp-3);
  border-radius: var(--br-2);
  z-index: var(--z-index-toast);
  font-size: var(--fs-3);
}

.toast_success {
  background-color: var(--plt-success-500);
}

.toast_error {
  background-color: var(--plt-danger-500);
}
