:root {
  /* --ff-0: "Source Sans Pro", sans-serif; */
  /* --ff-0: "Lato", sans-serif; */
  --ff-0: "Inter", sans-serif;
  --ff-1: "Roboto Slab", sans-serif;
  /* --ff-2: "Montserrat", sans-serif; */

  /* type scale */
  /* p91 */
  --fs-0: 13px;
  --fs-1: 14px;
  --fs-2: 15px;
  --fs-3: 16px;
  --fs-4: 18px;
  --fs-5: 20px;
  --fs-6: 24px;
  --fs-7: 30px;
  --fs-8: 36px;
  --fs-9: 48px;
  --fs-A: 60px;
  --fs-B: 72px;
  --fs-C: 6px;
  --fs-D: 12px;
  --fs-E: 8px;
  --fs-F: 10px;

  /* spacing */
  --sp-0: 2px;
  --sp-1: 4px;
  --sp-2: 8px;
  --sp-3: 12px;
  --sp-4: 16px;
  --sp-5: 24px;
  --sp-6: 32px;
  --sp-7: 48px;
  --sp-8: 64px;
  --sp-9: 96px;
  --sp-A: 128px;
  --sp-B: 256px;
  --sp-C: 384px;
  --sp-D: 512px;
  --sp-E: 640px;
  --sp-F: 768px;

  /* border-radius */
  --br-0: 1px;
  --br-1: 2px;
  --br-2: 4px;
  --br-3: 8px;
  --br-4: 12px;
  --br-5: 16px;

  /* box-shadow */
  --box-shadow-0: 0 2px 10px var(--plt-neutral-200);
  --box-shadow-1: 0 2px 10px var(--plt-neutral-300);

  --z-index-sidebar: 2;
  --z-index-header-menu: 3;
  --z-index-header: 4;
  --z-index-tooltip: 5;
  --z-index-floating-action-button: 6;
  --z-index-dialog-overlay: 7;
  --z-index-dialog-content: 8;
  --z-index-toast: 9;

  --input-height: 46px;
  --input-sm-height: 34px;

  --button-height: 38px;
  --button-sm-height: 34px;

  --header-height: 56px;
  --header-public-height: 80px;
  --sidebar-width: 70px;
  --content-limit-width: 900px;

  --pg-gutter-width: var(--sp-7);
  --pg-background-color: var(--plt-neutral-000);
}

body {
  font-family: var(--ff-0);
  font-size: var(--fs-2);
  font-display: optional;
  line-height: 1.4;
  color: var(--plt-neutral-800);
  /* https://stackoverflow.com/a/11885330 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p {
  line-height: 1.5;
}

/* ::selection {
  background-color: var(--plt-supporting-2-000);
} */

::placeholder {
  color: var(--plt-neutral-500);
}
