.error_root {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--sp-6);
}

.error_container_text {
  padding: var(--sp-5) var(--sp-6);
  background-color: white;
  border-radius: var(--br-2);
  border: 1px solid var(--plt-neutral-200);
  font-size: var(--fs-3);
}
