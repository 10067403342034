.sidebar {
  position: fixed;
  top: var(--header-height);
  height: calc(100vh - var(--header-height));
  display: grid;
  width: var(--sidebar-width);
  border-right: 1px solid var(--plt-neutral-000);
  background-color: white;
}

.logo {
  font-size: var(--fs-5);
  font-weight: 700;
  color: var(--plt-primary-800);
  padding: var(--sp-2) var(--sp-1) 0;
}

.nav {
  overflow-y: auto;
}

.nav_list {
  display: grid;
  justify-content: center;
  gap: var(--sp-1) 0;
  margin: 0;
  list-style: none;
  padding: var(--sp-2) var(--sp-1);
}

.nav_link {
  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;
  color: var(--plt-neutral-300);
  text-decoration: none;
  height: 50px;
  font-size: var(--fs-2);
  font-family: inherit;
  font-weight: 500;
  padding: var(--sp-2) var(--sp-3);
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 100%;
  outline: none;
  border-radius: var(--br-3);
}

.nav_link_collapsed {
  justify-content: center;
}

.nav_link_text {
  margin-inline-start: 0px;
}

.nav_link:hover {
  background-color: var(--plt-primary-000);
}

.nav_link_active,
.nav_link_active:hover {
  color: var(--plt-primary-800);
  background-color: var(--plt-primary-000);
  font-weight: 500;
}

.nav_link_icon {
  fill: currentColor;
  width: 22px;
  height: 22px;
  vertical-align: top;
  /* For better alignment */
  margin-block-start: -1px;
}

.nav_link_icon_active,
.nav_link_icon_active:hover {
  background-color: var(--plt-primary-000);
}

.collapse_button_container {
  align-self: end;
  border-top: 1px solid var(--plt-neutral-100);
}

.collapse_button_container > button {
  width: 100%;
  justify-content: start;
  fill: var(--plt-neutral-400);
  border-radius: 0;
  padding: var(--sp-4) var(--sp-3);
  height: initial;
}

.collapse_button_container_collapsed > button {
  justify-content: center;
}
